import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DropzoneArea } from 'react-mui-dropzone';
import { useTranslation } from '../common/components/LocalizationProvider';
import { Button, CircularProgress, Typography, Box } from '@mui/material';
import { getWithExpiry } from '../common/util/localstorage';

const ImageUpload = ({ agencyId }) => {
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false); // Spinner for upload
  const [currentLogo, setCurrentLogo] = useState(null); // Stores the current logo URL
  const [fetchingLogo, setFetchingLogo] = useState(true); // Spinner for fetching logo
  const t = useTranslation();

  // Fetch the current logo when the component mounts
  useEffect(() => {
    const fetchCurrentLogo = async () => {
      try {
        const baseURL = process.env.REACT_APP_URL_NAME || 'http://localhost:80';
        const res = await fetch(`${baseURL}/agences/${agencyId}`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${getWithExpiry("TOKEN")}`,
          },
        });
        setCurrentLogo(res.data.agence_logo_url); // Backend should return this field
      } catch (err) {
        console.error('Error fetching current logo:', err);
        setMessage(t('sharedFetchLogoError')); // Translation key for fetch error
      } finally {
        setFetchingLogo(false); // Hide spinner after fetching
      }
    };

    fetchCurrentLogo();
  }, [agencyId]);

  const onChange = (e) => {
    setFile(e[0]);
    setMessage(''); // Clear any previous messages
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!file) {
      setMessage(t('sharedNoFileSelected'));
      return;
    }

    const formData = new FormData();
    formData.append('image', file);

    setLoading(true);
    setMessage('');

    try {
      const baseURL = process.env.REACT_APP_URL_NAME ;

      const res = await axios.post(
        `${baseURL}/agences/${agencyId}/uploadimage`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'x-base-url': baseURL,
          },
        }
      );

      setMessage(res.data.message); // Display the success message
      setCurrentLogo(`${baseURL}/uploads/images/${file.name}`); // Update displayed logo
      setFile(null);
    } catch (err) {
      if (err.response?.status === 500) {
        setMessage(t('sharedServerError'));
      } else {
        setMessage(err.response?.data?.message || t('sharedUploadError'));
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      {/* Show loading spinner while fetching logo */}
      {fetchingLogo ? (
        <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
          <CircularProgress />
        </Box>
      ) : (
        currentLogo && (
          <Box mb={2} textAlign="center">
            <Typography variant="h6">{t('sharedCurrentLogo')}</Typography>
            <img
              src={currentLogo}
              alt="Current Logo"
              style={{ maxWidth: '200px', maxHeight: '200px', borderRadius: '8px' }}
            />
          </Box>
        )
      )}

      <DropzoneArea
        dropzoneText={t('sharedDropzoneText')}
        acceptedFiles={['image/png']}
        filesLimit={1}
        onChange={onChange}
        showAlerts={false}
        maxFileSize={500000} // 500 KB
      />
      <Box display="flex" alignItems="center" mt={2}>
        <Button
          variant="outlined"
          onClick={onSubmit}
          disabled={loading || fetchingLogo} // Disable button if loading
        >
          {loading ? <CircularProgress size={24} /> : t('sharedUpload')}
        </Button>
      </Box>
      {message && (
        <Typography
          variant="body2"
          color={message.includes('successfully') ? 'green' : 'red'}
          mt={2}
        >
          {message}
        </Typography>
      )}
    </Box>
  );
};

export default ImageUpload;
