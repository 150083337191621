import { minHeight } from "@mui/system";
import React, { useEffect, useState } from "react";

import "../ledDisplays/card.css";
export default function Card(props) {
  const height = 100 / props.number + "%";

  return (
    props.size=="sm" && props.lang=="ar"?
      <div
        className="card-s"
        style={{
          display: "flex",
          height: height,
          minHeight: height,
          borderBottom: "0.1px solid #808080",
          color:props.item.trip_schedule_relationship==="UNSCHEDULED"?"orange":props.item.trip_schedule_relationship==="CANCELED"?"red":"white"
        }}
      >
        <div
          style={{
            width: "22%",
            justifyContent: "right",
            display: "inline-flex",
            "align-items": "center",
            "justify-content": "center",
            color:props.item.trip_schedule_relationship==="UNSCHEDULED"?"orange":props.item.trip_schedule_relationship==="CANCELED"?"red":"#00FF00"

           
          }}
        >
          {props.item.trip_departure_time}
        </div>

        <div
          style={{
            width: "60%",
            display: "inline-flex",
            "align-items": "center",
            "justify-content": "center",
            color: props.color,

          }}
        >
          {props.item.trip_destination_Ar}{" "}
        </div>

        <div
          style={{
            width: '17%',
            display: 'inline-flex',
            'align-items': 'center',
            'justify-content': 'center',
            
            color:"#ffd700"

          }}
        >
          {props.item.trip_id}
        </div>
     
       
      </div>:props.size=="sm" && props.lang=="fr"? <div
        className="card-s"
        style={{
          display: "flex",
          height: height,
          minHeight: height,
          borderBottom: "0.1px solid #808080",
          color:props.item.trip_schedule_relationship==="UNSCHEDULED"?"orange":props.item.trip_schedule_relationship==="CANCELED"?"red":"white"

        }}
      >
        <div
          style={{
            width: "17%",
            justifyContent: "left",
            display: "inline-flex",
            "align-items": "center",
            "justify-content": "center",
            paddingLeft:"1px",
            color:"#ffd700"

           
          }}
        >
          {props.item.trip_id}
        </div>

        <div
          style={{
            width: "60%",
            justifyContent: "left",
            display: "inline-flex",
            "align-items": "center",
            "justify-content": "center",
            color: props.color,

          }}
        >
          {props.item.trip_destination_Fr}{" "}
        </div>

        <div
          style={{
            width: '22%',
            display: 'inline-flex',
            'align-items': 'center',
            'justify-content': 'center',
            color:props.item.trip_schedule_relationship==="UNSCHEDULED"?"orange":props.item.trip_schedule_relationship==="CANCELED"?"red":"#00ff00"

          }}
        >
          {props.item.trip_departure_time}
        </div>
     
       
      </div>: <div
        className="card-s"
        style={{
          display: "flex",
          height: height,
          minHeight: height,
          borderBottom: "0.1px solid #808080",
          color:props.item.trip_schedule_relationship==="UNSCHEDULED"?"orange":props.item.trip_schedule_relationship==="CANCELED"?"red":"white"

        }}
      >
        <div
          style={{
            width: "8%",
            justifyContent: "center",
            display: "inline-flex",
            "align-items": "center",
            "justify-content": "left",
            paddingLeft:"10px",
            color:"#ffd700"

           
          }}
        >
          {props.item.trip_id}
        </div>

        <div
          style={{
            width: "30%",
            justifyContent: "center",
            display: "inline-flex",
            "align-items": "center",
            "justify-content": "center",
            color: props.color,

          }}
        >
          {props.item.trip_destination_Fr}{" "}
        </div>

        <div
          style={{
            width: '24%',
            display: 'inline-flex',
            'align-items': 'center',
            'justify-content': 'center',
            color:props.item.trip_schedule_relationship==="UNSCHEDULED"?"orange":props.item.trip_schedule_relationship==="CANCELED"?"red":"#00FF00"
          }}
        >
          {props.item.trip_departure_time}
        </div>
        <div
          style={{
            width: '30%',
            display: 'inline-flex',
            'align-items': 'center',
            'justify-content': 'center',
          }}
        >
            {props.item.trip_destination_Ar}
        </div>
        <div
          style={{
            width: '8%',
            display: 'inline-flex',
            'align-items': 'center',
            'justify-content': 'right',
            paddingRight:"10px",
            color:"#ffd700"

          }}
        >
            {props.item.trip_id}
        </div>
      </div>
   
  );
}
