import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { useEffectAsync } from "../../../reactHelper";
import ErrorDialog from "../../../common/components/ErrorDialog";
import './header.css';
import { useDispatch } from 'react-redux';
import HeaderData from './HeaderData';
import NavBar from '../navbar/Navbar';
export default function Header() {
  const [loading, setLoading] = useState(false);
  const [selectedAgency, setSelectedAgency] = useState({});

  const dispatch = useDispatch();
  const { agency_name } = useParams();
  const { size } = useParams();
  const { prop } = useParams();
  const { lang } = useParams();

  const [opening, setOpening] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Error !!");
  const navigate = useNavigate();

  useEffectAsync(async () => {


    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL_NAME}/agence/${agency_name}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setSelectedAgency(data[0])

        return;
      }
      if (response.status === 401) {
        setErrorMsg("UNAUTHORIZED");
        setOpening(true);
        navigate("/login");
      } else {
        setErrorMsg(await response.text());
        setOpening(true);
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  }, []);
  const handleOpeningResult = (opening) => {
    setOpening(false);
    if (opening) {
    }
  };
  return (<>
   {(prop!="p3")? <HeaderData size={size} prop={prop} lang={lang} logoUrl={selectedAgency.agence_logo_url} agncyFullNameAr={selectedAgency.agence_full_name_Ar} agncyFullNameFr={selectedAgency.agence_full_name_Fr}/>:<></>}
   <NavBar lang={lang} size={size} bgColor={selectedAgency.agence_color}/>
  </>
  );
}
