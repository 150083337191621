import React, { useEffect, useState } from "react";
import {
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Typography,
} from "@mui/material";
import { useEffectAsync } from "../reactHelper";
import { getWithExpiry } from "../common/util/localstorage";
import SearchHeader, {
  filterByKeyword,
} from "../common/components/SearchHeader";

import CollectionActions from "../common/components/CollectionActions";
import { agenciesActions } from "../store";
import TableShimmer from "../common/components/TableShimmer";
import { useDispatch, useSelector } from "react-redux";
import CollectionFab from "../settings/CollectionFab";
import { useNavigate } from "react-router-dom";
import ErrorDialog from "../common/components/ErrorDialog";
import TextToSpeech from "./TextToSpeech";
import SquareIcon from '@mui/icons-material/Square';
import { useTranslation } from '../common/components/LocalizationProvider';

const AgenciesPage = () => {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const _agencies = useSelector((state) => state.agencies.items);
  //console.log("AGENCY FROM STORE", _agencies);
  const [opening, setOpening] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Error !!");
  const _userId = useSelector((state) => state.session.user._id);
  const _userRole = useSelector((state) => state.session.user.role);

  const t = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffectAsync(async () => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_URL_NAME}/useragences?userId=${_userId}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getWithExpiry("TOKEN")}`,
        },
      });
      if (response.ok) {
        response
          .clone()
          .json()
          .then((data) => {
            console.log("Agencies::", data);
            dispatch(agenciesActions.setItems(data));
          });
        setItems(await response.json());
        return;
      }
      if (response.status === 401) {
        setErrorMsg("UNAUTHORIZED");
        setOpening(true);
        //console.log("UNAUTHORIZED::", response.status);
        navigate("/login");
      } else {
        setErrorMsg(await response.text());
        setOpening(true);
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  }, []);
  const handleOpeningResult = (opening) => {
    setOpening(false);
    if (opening) {
    }
  };
  //console.log("items ", items);
  return (
    <>
      <Typography
        variant="h2"
        component="h2"
        align="center"
      >
        {t('sharedAgencies')}
      </Typography>
      <SearchHeader keyword={searchKeyword} setKeyword={setSearchKeyword} />

      <Table>
        <TableHead>
          <TableRow style={{ backgroundColor: "#192841", color: "#fff" }}>

            <TableCell style={{ color: "#fff" }}>{t('sharedCodeLine')}</TableCell>
            <TableCell style={{ color: "#fff" }}>{t('sharedAgencyName')}</TableCell>
            <TableCell style={{ color: "#fff" }}>{t('sharedFullNameAr')}</TableCell>
            <TableCell style={{ color: "#fff" }}>{t('sharedFullNameFr')}</TableCell>
            <TableCell style={{ color: "#fff" }}>{t('sharedTripsNumber')}</TableCell>
            <TableCell style={{ color: "#fff" }}> {t('sharedAgencyPrimaryColor')}</TableCell>
            <TableCell style={{ color: "#fff" }}> {t('sharedAgencyLogo')}</TableCell>

            <TableCell style={{ color: "#fff" }}>{t('sharedMessage')}</TableCell>
            <TableCell style={{ color: "#fff" }}>{t('sharedLat')}</TableCell>
            <TableCell style={{ color: "#fff" }}>{t('sharedLng')}</TableCell>

            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading && _agencies && _agencies.length > 0 ? (
            _agencies.filter(filterByKeyword(searchKeyword)).map((item) => (
              <TableRow key={item._id}>
                <TableCell>{item.agence_id}</TableCell>
                <TableCell>{item.agence_name}</TableCell>
                <TableCell>{item.agence_full_name_Ar}</TableCell>
                <TableCell>{item.agence_full_name_Fr}</TableCell>
                <TableCell>{item.agence_trips.length}</TableCell>
                <TableCell> <SquareIcon style={{ color: item.agence_color }} /></TableCell>
                <TableCell><img style={{width:'60px',height:'40px'}} src={item.agence_logo_url} />

                </TableCell>

                <TableCell>{item.agence_message}</TableCell>
                <TableCell>{item.agence_latitude}</TableCell>
                <TableCell>{item.agence_longitude}</TableCell>





                <TableCell padding="none">
                  <CollectionActions
                    itemId={item._id}
                    editPath="/settings/agence"
                    endpoint="useragences"
                    removeendpoint="agences"
                    action={agenciesActions}
                    removable={true}
                    editable={true}

                  />
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableShimmer columns={3} endAction />
          )}
        </TableBody>
      </Table>
      {_userRole != "user" ? <CollectionFab editPath="/settings/agence" /> : <></>}
      <ErrorDialog
        style={{ transform: "none" }}
        open={opening}
        errorMsg={errorMsg}
        onResult={handleOpeningResult}
      />
    </>
  );
};

export default AgenciesPage;
