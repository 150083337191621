import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";


import { agencytripsActions } from "../../../store";
import { useEffectAsync } from "../../../reactHelper";
import ErrorDialog from "../../../common/components/ErrorDialog";
import LedAgencyTripsDisplayer from "../LedAgencyTripsDisplayer";
import { useParams } from 'react-router-dom';

import VisibilityTrigger from "../VisibilityTrigger";

import LedSoretrakTripsDisplayer from "./LedSoretrakTripsDisplayer";
import SoretrakHeader from "./SoretrakHeader";
import SoretrakFooter from "./SoretrakFooter";


export default function SoretrakDisplayer() {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { size } = useParams();
    const { prop } = useParams();
    const { lang } = useParams();
    const { nline } = useParams();

    const [opening, setOpening] = useState(false);
    const [selectedAgency, setselectedAgency] = useState([]);
    const [agencyTrips, setAgencyTrips] = useState([]);


    const [errorMsg, setErrorMsg] = useState("Error !!");




    useEffectAsync(async () => {


        setLoading(true);
        try {
            const response = await fetch(
                `https://displays.emkatech.tn/api/soretrak/trips`,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                }
            );
            if (response.ok) {
                //  console.log("RES OK");
                const data = await response.json();

                setAgencyTrips(data);
                console.log("soretrak trips",agencyTrips);

                return;
            }
            if (response.status === 401) {
                setErrorMsg("UNAUTHORIZED");
                setOpening(true);
                navigate("/login");
            } else {
                setErrorMsg(await response.text());
                setOpening(true);
                throw Error(await response.text());
            }
        } finally {
            setLoading(false);
        }
    }, []);
    useEffectAsync(async () => {

        setLoading(true);
        try {
            const response = await fetch(
                `https://displays.emkatech.tn/api/agence/soretrak`,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                }
            );
            if (response.ok) {
                //  console.log("RES OK");
                const data = await response.json();
                setselectedAgency(data);
                console.log("soretrak agency",selectedAgency)
                return;
            }
            if (response.status === 401) {
                setErrorMsg("UNAUTHORIZED");
                setOpening(true);
                navigate("/login");
            } else {
                setErrorMsg(await response.text());
                setOpening(true);
                throw Error(await response.text());
            }
        } finally {
            setLoading(false);
        }
    }, []);
    const handleOpeningResult = (opening) => {
        setOpening(false);
        if (opening) {
        }
    };

    return (
        <>
            <SoretrakHeader selectedAgency={{}} />

            <main
                style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    backgroundColor: "#000",
                    color: "white",
                }}
            >
                <LedSoretrakTripsDisplayer
                    size={size}
                    endpoint={`https://displays.emkatech.tn/api/soretrak/trips`}
                    trips={agencyTrips}

                    sliceNumber={parseInt(nline)}
                    lang={lang}
                />
            </main>
            <footer style={{ position: "sticky", bottom: 0 }}>
                <SoretrakFooter size={size} selectedAgency={selectedAgency} />
            </footer>

            <ErrorDialog
                style={{ transform: "none" }}
                open={opening}
                errorMsg={errorMsg}
                onResult={handleOpeningResult}
            />
        </>
    );
}
